import {Accordion, useAccordionButton, Card, Button} from 'react-bootstrap';
import {getEpisodeDescriptions, playEpisode, pauseEpisode} from './../../services/spotifyServices';
import PlayButton from '../PlayButton/PlayButton'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { useState} from 'react';
import { useAuth } from '../../contexts/AuthContext';
import styles from './TopicsAccordian.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
    
function TopicsAccordian({topics}) {

    const accordionTopic = {
        width: '1000px',
    }

    const [descriptions, setDescriptions] = useState({});
    const [activeTopic, setActiveTopic] = useState(null);
    const [episodePlaying, setEpisodePlaying] = useState(null);
    const [topicExpanded, setTopicExpanded] = useState(false);
    const [lastSelectedTopic, setLastSelectedTopic] = useState(null);
    const { spotifyIsAuthenticated, setIsAuthenticated } = useAuth();

    const togglePlayPause = (episodeID, e) => {
        e.stopPropagation();
        if (episodePlaying === episodeID) {
            setEpisodePlaying(null);
            pauseEpisode(episodeID);
        }
        else {
            setEpisodePlaying(episodeID);
            playEpisode(episodeID);
        }
    }

    const toggleTopicExpanded = () => {
        setTopicExpanded(!topicExpanded)
    }

    const handleTopicClick = async (episodesArray, topic) => {

        if (lastSelectedTopic != topic) {
            const episodeIDs = Object.keys(episodesArray);
            const episodeIDsString = episodeIDs.join(",");
            const descriptions = await getEpisodeDescriptions(episodeIDsString, setIsAuthenticated);
            console.log(descriptions);
            setDescriptions(descriptions);
            setLastSelectedTopic(topic);
        }
    }

    function AccordionTopicToggle({children, eventKey, onTopicClick}) {

        const decoratedOnClick = useAccordionButton(eventKey, (e) => {
            onTopicClick();
            if(activeTopic === eventKey) {
                setActiveTopic(null);
            }
            else {
                setActiveTopic(eventKey);
            }
        });
    
        return (
            <Card.Header
              className={activeTopic === eventKey ? styles.accordionTopicActive : styles.accordionTopic}
              onClick={decoratedOnClick}
            >
              {children}
            </Card.Header>
        );
    }

    function AccordionEpisodeToggle({children, eventKey}) {

        const decoratedOnClick = useAccordionButton(eventKey);
        
        return (
          <Card.Header
            className={styles.accordionEpisode}
            onClick={decoratedOnClick}
          >
            {children}
          </Card.Header>
        );
    }
     
    return (
        <Accordion defaultActiveKey="0" style={accordionTopic}>
            {Object.entries(topics).map( ([topic, episodesArray], i) => (
                <>
                    <Card className={styles.accordionCard} key={topic}>
                        <AccordionTopicToggle eventKey={`topic-${i}`} 
                                              onTopicClick= {() => handleTopicClick(episodesArray, topic)}>
                            {String(topic)}
                        </AccordionTopicToggle>
                        <Accordion.Collapse eventKey={`topic-${i}`}>
                            <Card.Body>
                                <Accordion>
                                {Object.entries(episodesArray).map(([episodeID, title], j) =>  (
                                    <div>
                                        <AccordionEpisodeToggle eventKey={`topic-${i}-episode-${j}`}>
                                            {title}
                                            <div onClick={(e) => {togglePlayPause(episodeID, e)}}>
                                            {(episodePlaying === episodeID) ?
                                                <PauseCircleIcon className={styles.playbackButton}/>
                                                :
                                                <PlayCircleIcon className={styles.playbackButton}/>
                                            }
                                            </div>
                                        </AccordionEpisodeToggle>
                                        <Accordion.Collapse eventKey={`topic-${i}-episode-${j}`}>
                                            <Card.Body>
                                                {descriptions[episodeID] ? descriptions[episodeID] : 'Fetching...'}
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </div>
                                  
                                ))}
                            </Accordion>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </>
            ))}
        </Accordion>
    );
}
export default TopicsAccordian