import {Button} from 'react-bootstrap';
import { playEpisode } from './../../services/spotifyServices';

function PlayButton({episodeID}) {

    return(
        <Button variant= "outline-primary" size="sm" onClick={() => playEpisode(episodeID)}>
            Play
        </Button>
    )
}

export default PlayButton;
