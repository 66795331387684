import SignInForm from '../SignInForm/SignInForm.js';
import styles from "./SignInFormModal.module.scss"

function SignInFormModal() {
    return (
        <div className={styles["signin-modal-overlay"]}>
            <SignInForm/>
        </div>
    )
}

export default SignInFormModal;