import styles from './SearchBar.module.scss';
import { searchShows } from "../../services/spotifyServices";
import { useState } from 'react';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import 'font-awesome/css/font-awesome.min.css';

import {Input} from 'antd';

function SearchBar({ updateShows, setShowsLoading }) {
    const [searchInput, setSearchInput] = useState("");
    const { spotifyIsAuthenticated, setIsAuthenticated } = useAuth();

    const search = async () => {
        setShowsLoading(true);
        const searchResults = await searchShows(searchInput, setIsAuthenticated);
        updateShows(searchResults);
        setShowsLoading(false);
    };

    return (
            <InputGroup className={`mb-3 ${styles.searchBar}`} size="lg">
                <FormControl className={`search-input ${styles.customInput}`}
                    placeholder='Search for Podcast'
                    type='input'
                    onKeyPress={event => {
                        if (event.key === 'Enter') {
                            search();
                        }
                    }}
                    onChange={event => setSearchInput(event.target.value)}
                />
                <Button className={`search-button ${styles.customButton}`} onClick={search}>
                    <i className="fa fa-search"></i>
                </Button>
            </InputGroup>
    );
}

export default SearchBar;