import { json } from "react-router-dom";

async function pollTopics(referenceId) {
    const endpoint = `/BERTopic/topics-status/${referenceId}/`;
    let response

    do {
        try{
            response = await fetch(endpoint);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            };

            if (response.status == 202) {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error.message);
            return [];  // Return an empty array in case of an error
        }
    }
    while (response.status == 202);

    if (response.status == 200) {
        const jsonResponse = await response.json();
        const data = jsonResponse.data;
        console.log(typeof data);
        return data;
    } else {
        // Handle other status codes or scenarios
        console.error(`Unexpected response status: ${response.status}`);
        return [];
    }
}


async function getTopics(show_id, publisher, min_topic_size, corpus_type, use_gpt, setIsAuthenticated) {
    const endpoint = '/BERTopic/topics/';
    console.log("show_id: " + show_id)
    console.log("publisher: " + publisher)
    console.log("min_topic_size: " + min_topic_size)
    console.log("corpus_type: " + corpus_type)
    console.log("use_gpt: " + use_gpt)

    // Prepare the payload for the POST request
    const payload = {
        "body": {
            show_id: show_id,
            publisher: publisher,
            min_topic_size: min_topic_size,
            corpus_type: corpus_type,
            use_gpt: use_gpt}
    };


    
    try {
        const response = await fetch(endpoint, {
            method: 'POST',       // Set the method to POST
            headers: {
                'Content-Type': 'application/json',  // Set the content type header
            },
            body: JSON.stringify(payload)  // Convert the payload to a JSON string and set as the body
        });
 
        if (response.status === 403) {
            if (setIsAuthenticated)
                setIsAuthenticated(false);
            console.error("Access Forbidden");
            return null;
        };

        if (!response.ok) {
            throw new Error('Network response was not ok');
        };
        
        const jsonResponse = await response.json();
        const referenceId = jsonResponse.reference_id;


        const data = await pollTopics(referenceId)
        if (data) {
            return data
        }
        else {
            console.error("Failed to retrieve topics data.");
            return null;
        }

    } catch (error) {
        console.error("There was a problem with the fetch operation:", error.message);
        return [];  // Return an empty array in case of an error
    }
}

export default getTopics;