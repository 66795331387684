import { useParams, useLocation } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { Container } from 'react-bootstrap';
import getTopics from './../../services/BERTopicServices';
import {getColor} from './../../services/spotifyServices';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import TopicsAccordion from './../../components/TopicsAccordian/TopicsAccordian';
import styles from './DisplayTopics.module.scss'
import  { extractColors } from 'extract-colors'

function DisplayTopics({navSidebarExpanded,
                        secondSidebarExpanded, 
                        toggleSecondSidebar,
                        toggleTopicsLoading,
                        topicsLoading,
                        topics,
                        currLoadedShow,}) {

    const location = useLocation();
    const show_id = location.state ? location.state.show.id : null
    const image_url = location.state ? location.state.show.images[0].url : null
    const publisher = location.state ? location.state.show.publisher : null

    const image_url_ref = useRef(null);
    const show_text_ref = useRef(null);
    const [podcastTitleClass, setPodcastTitleClass] = useState(styles.podcastTitleRow);
    const [podcastTitleClassText, setPodcastTitleClassText] = useState(styles.podcastTitleText);
    const [podcastTitleColor, setPodcastTitleColor] = useState('linear-gradient(180deg, #000 0, grey)');

    useEffect(() => {

        const normalize = (value, min, max) => {
            return (value - min) / (max - min);
        };

        const fetchColor = async () => {
            try {
                const response = await fetch(image_url, { mode: 'cors'});
                const blob = await response.blob();
                const imageUrl = URL.createObjectURL(blob);
                const colors = await extractColors(imageUrl);

                const weightArea = 1;
                const weightSaturation = 1;
                const saturationThreshold = .1;


                const maxArea = Math.max(...colors.map(c => c.area))
                const minArea = Math.min(...colors.map(c => c.area))
                const maxSaturation = Math.max(...colors.map(c => c.saturation))
                const minSaturation = Math.min(...colors.map(c => c.saturation))
                
                /*
                const isWhite = (color) => color.saturation < 0.1 && color.lightness > 0.9;
                const isBlack = (color) => color.saturation < 0.1 && color.lightness < 0.1;
                */

                const scoredColors = colors.map(color => {
                    const normalizedArea = normalize(color.area, minArea, maxArea);
                    const normalizedSaturation = normalize(color.saturation, minSaturation, maxSaturation);
                    const penalty = color.saturation < saturationThreshold ? 1 : 0;
                    const score = + (weightArea * normalizedArea) 
                                  //+ (weightSaturation * normalizedSaturation);
                                  - penalty 
                    return { ...color, score, normalizedArea, normalizedSaturation};
                });

                const sortedColors = scoredColors.sort((a, b) => b.score - a.score);
                console.log(sortedColors)
                const highestScoredColor = sortedColors[0]
                setPodcastTitleColor(highestScoredColor.hex);

            } catch (error) {
                console.error('Error extracting colors:', error);
                throw error;
            }
        };
        
        if (image_url) {
            fetchColor()
        }
        
  }, [image_url]);

    useEffect(() => {
      if (!secondSidebarExpanded) {
          toggleSecondSidebar();
      }
    }, [secondSidebarExpanded, toggleSecondSidebar]);


    return (
        <>
            <div className={styles.displayTopics}>
                <div /*className={styles.podcastTitle}*/ style={{ background: `linear-gradient(3deg, black -100%, ${podcastTitleColor} 100%)`}}>
                    <div className={`${podcastTitleClass}
                                    ${navSidebarExpanded ? styles.pageLeftNavExp : styles.pageLeft}`}>
                            <img ref={image_url_ref} src={image_url} alt="Show Image" className={styles.podcastTitleImage} />
                            <div ref={show_text_ref} className={podcastTitleClassText}>
                            {location.state.show.name}
                            </div>
                    </div>
                </div>
                {/*<div className={`${styles.divider}
                                ${navSidebarExpanded ? styles.pageLeftNavExp : styles.pageLeft}`}></div>*/}
                <div style ={{background: `linear-gradient(180deg, ${podcastTitleColor} 0%, rgb(2 2 2) 150%)`,
                              minHeight: '100vh'}}>
                    {topicsLoading && <div className={`${styles.topicsDisplay}
                                                    ${navSidebarExpanded ? styles.topicsDisplayNavExp: ""}
                                                    ${styles.loadingIcon}`}>
                        <LoadingScreen/>
                    </div>}
                    {topics && !topicsLoading && show_id === currLoadedShow && <div className={`${styles.topicsDisplay}
                                                                            ${navSidebarExpanded ? styles.topicsDisplayNavExp: ""}`}>
                        <TopicsAccordion topics={topics}/>
                    </div>}
                </div>
            </div>
        </>
    );
}

export default DisplayTopics;





