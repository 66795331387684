import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, InputGroup, FormControl, Button, Row, Card} from 'react-bootstrap';
import { useState, useEffect } from 'react';
import SearchBar from './../../components/SearchBar';
import ShowList from './../../components/ShowList';
import ShowListLoadIcon from '../../components/ShowListLoadIcon/ShowListLoadIcon';
import {authenticateSpotify} from './../../services/spotifyServices';
import styles from './FindShows.module.scss'
import logo from './../../assets/logo_wave.png';

function FindShows({secondSidebarExpanded, toggleSecondSidebar}) {

  const showList = {
    marginTop: '100px',
  };


  const [access_token, setAccessToken] = useState("");
  const [spotifyAuthenticated, setSpotAuth] = useState(false);
  const [shows, setShows] = useState([]);
  const [showsLoading, setShowsLoading] = useState(false);

  const handleUpdateShows = (searchResults) => {
    setShows(searchResults);
  } 

  useEffect(() => {
    if (secondSidebarExpanded) {
      toggleSecondSidebar();
    }
    //authenticateSpotify();
  }, []);

  return (
    <div className={styles.findShows}>
      <div style={{ height: '20px' }}></div>
      <div className={styles.logo}>
        <img src={logo} alt="Logo" className={styles.logoImage}/>
      </div>
      <div className={styles.searchBarContainer}>
        <SearchBar updateShows={handleUpdateShows} setShowsLoading={setShowsLoading}/>
      </div>
      {showsLoading && <div className={styles.loadIcon}>
        <ShowListLoadIcon/>
      </div>}

      {!showsLoading && <Container className={styles.showList}>
        <ShowList shows={shows}/>
      </Container>}
    </div>
  );
}

export default FindShows;