import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import getTopics from './../../services/BERTopicServices';
import {AwesomeButton, AwesomeButtonProgress} from 'react-awesome-button';
import awsStyle from './awesomeButton.module.scss';
import styles from './TopicsSetting.module.scss';
import { useAuth } from '../../contexts/AuthContext';


function TopicsSetting({topicsLoading, toggleTopicsLoading, setTopics, setCurrLoadedShow}) {

    // Params for Topics API
    const location = useLocation();
    const show_id = location.state ? location.state.show.id : null;
    const publisher = location.state ? location.state.show.publisher : null;
    const [numberTopics, setNumberTopics] = useState(9);
    const [corpusType, setCorpusType] = useState("names");
    const [useGPT, setUseGPT] = useState(false);
    const { spotifyIsAuthenticated, setIsAuthenticated } = useAuth();

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    async function handleGetTopics(show_id, publisher, numberTopics, corpusType, useGPT) {
        const fetchedTopics = await getTopics(show_id, publisher, numberTopics, corpusType, useGPT, setIsAuthenticated);
        setTopics(fetchedTopics);
    }
    
    async function handleClickGenerate(show_id, publisher, numberTopics, corpusType, useGPT) {
        toggleTopicsLoading();
        await handleGetTopics(show_id, publisher, numberTopics, corpusType, useGPT);
        toggleTopicsLoading(); 
        setCurrLoadedShow(show_id);
    }
    
    // Params for CSS
    const [sliderValue, setSliderValue] = useState(16);
    const sliderProps = {
        fill: "#0B1EDF",
        background: "rgba(255, 255, 255, 0.214)",
    };

    useEffect(() => {
        const applyFill = (value) => {
        const percentage = (100 * (value - 4)) / (32 - 4);
        return `linear-gradient(90deg, ${sliderProps.fill} ${percentage}%, ${sliderProps.background} ${percentage + 0.1}%)`;
        };

        document.getElementById("slider").style.background = applyFill(sliderValue);
    }, [sliderValue, sliderProps.fill, sliderProps.background]);

    return (
        
        <div className={styles.container}>
            <div className={styles.range_slider_container}>
                <div className={`${styles.length} ${styles.range__slider}`} data-min="Less Topics" data-max="More Topics">
                    <div className={`${styles.length__title} ${styles.field_title}`} data-length={numberTopics}>Topics Size:</div>
                    <input
                    id="slider"
                    type="range"
                    min="1"
                    max="10"
                    value={numberTopics}
                    onChange={(e) => setNumberTopics(e.target.value)}
                    />
                </div>
            </div>

        <div className={styles.settings}>
            <span className={`${styles.settings__title} ${styles.field_title}`}>Settings</span>
            <div className={styles.setting}>
                <input type="checkbox" 
                       id="uppercase" 
                       checked={corpusType === "names"}
                       onChange={() => setCorpusType(corpusType === "names" ? "descriptions" : "names")} />
                <label htmlFor="uppercase" checked={corpusType === "descriptions"}>Use Podcast Titles</label>
            </div>
            <div className={styles.setting}>
                <input type="checkbox" 
                       id="lowercase" 
                       checked={corpusType === "descriptions"}
                       onChange={() => setCorpusType(corpusType === "descriptions" ? "names" : "descriptions")}/>
                <label htmlFor="lowercase">Use Podcast Descriptions</label>
            </div>
        </div>
            <AwesomeButtonProgress type="primary"
                                cssModule={awsStyle}
                                style={{marginTop: "20px", width: "100%"}}
                                onPress = {async (event, release) => {
                                    await handleClickGenerate(show_id, publisher, numberTopics, corpusType, useGPT);
                                    release();
                                }}
            >Generate Topics</AwesomeButtonProgress>
        </div>
    );
}

export default TopicsSetting;