async function fetchData(endpoint, options = {}, setIsAuthenticated = null) {
    try {
        const response = await fetch(endpoint, options)
        
        if (response.status === 403) {
            if (setIsAuthenticated)
                setIsAuthenticated(false);
            console.error("Access Forbidden")
            return null
        }

        if (!response.ok) {
            throw new Error('Network response not ok')
        }
        return await response.json();
    } catch (error) {
        console.error("Error: ", error);
        return null;
    }
}

async function searchShows(searchInput, setIsAuthenticated) {
    const endpoint = `/spotify/search-show/${searchInput}/`;
    const data = await fetchData(endpoint, undefined, setIsAuthenticated)
    return data ? data.shows.items.slice(0, 10) : [];
}

async function getEpisodeDescriptions(show_ids, setIsAuthenticated) {
    const endpoint = `/spotify/get-episode-descriptions/${show_ids}/`;
    return await fetchData(endpoint, setIsAuthenticated=setIsAuthenticated) || []
}

async function getDevices() {
    const endpoint = '/spotify/get-devices/';
    return await fetchData(endpoint) || []
}

async function playEpisode(show_id) {
    const deviceResponse = await getDevices();
    if (deviceResponse) {
        const device_id = deviceResponse.devices[0].id;
        const endpoint = `/spotify/play-episode/${show_id}/${device_id}/`;
        return await fetchData(endpoint, {method: 'PUT'}) || [];
    }
    return [];
}

async function pauseEpisode() {
    const deviceResponse = await getDevices();
    if (deviceResponse) {
        const device_id = deviceResponse.devices[0].id;
        const endpoint = `/spotify/pause-episode/${device_id}/`;
        return await fetchData(endpoint, {method: 'PUT'}, ) || [];
    }
    return [];
}

async function isAuthenticated() {

    const endpoint = '/spotify/is-authenticated';
    const data = await fetchData(endpoint);
    if (!data.status) {
        return false
    }
    return true
}

async function authenticateSpotify() {
    const endpoint = '/spotify/get-auth-url';
    const authData = await fetchData(endpoint);
    if (authData){
        window.location.replace(authData.url);
    }
}

async function authenticateGuest() {
    const endpoint = '/spotify/authenticate-guest';
    const response = await fetchData(endpoint, {method: "POST"});
    if (response){
        window.location.reload(true);
    }
}

export {searchShows};
export {getEpisodeDescriptions};
export {isAuthenticated};
export {authenticateSpotify};
export {authenticateGuest};
export {playEpisode};
export {pauseEpisode}