import styles from './SignInForm.module.scss'
import { authenticateSpotify, authenticateGuest } from './../../services/spotifyServices';

function SignInForm() {

    const spotifyLogin = () => {
        authenticateSpotify();
    }

    const guestLogin = () => {
        authenticateGuest();
    }

    return (
        <div className={styles['contact-wrapper']}>
            <header className={styles['login-cta']}>
                <h2>Account Login</h2>
            </header>
            <div className={styles['login-button']}>
                <button onClick={spotifyLogin}>Login with Spotify</button>
            </div>
            <div className={styles['guest-cta']}>
                <h2 onClick={guestLogin}>Continue As Guest</h2>     
            </div>
        </div>
    );
}

export default SignInForm;