import React, { createContext, useContext, useState, useEffect } from 'react'
import { isAuthenticated } from './../services/spotifyServices';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}


export const AuthProvider = ({ children, value, checkAuth }) => {
    //const { spotifyIsAuthenticated, setIsAuthenticated } = value;
    const setIsAuthenticated  = value;

    useEffect ((checkAuth) => {
        async function checkAuth(value) {
            const isAuth = await isAuthenticated();
            value.setIsAuthenticated(isAuth);
        }
        
        checkAuth(setIsAuthenticated);
    }, []);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

