import {ReactComponentElement, useEffect} from 'react';
import styles from './App.module.scss'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FindShows from './views/FindShows/FindShows';
import DisplayTopics from './views/DisplayTopics/DisplayTopics'
import SideBar from './components/SideBar/SideBar';
import { useState } from 'react';
import { Topic } from '@mui/icons-material';
import SignInFormModal from './components/SignInFormModal/SignInFormModal.js';
import _SandBox from './components/_Sandbox/_Sandbox.js';
import {isAuthenticated} from './services/spotifyServices.js'
import { AuthProvider } from './contexts/AuthContext.js';


function App() {
  
  const [navSidebarExpanded, setNavSidebarExpanded] = useState(false);
  const [secondSidebarExpanded, setSecondSidebarExpanded] = useState(false);
  const [topicsLoading, setTopicsLoading] = useState(false);
  const [topics, setTopics] = useState(null);
  const [currLoadedShow, setCurrLoadedShow] = useState(null); 
  const [spotifyIsAuthenticated, setIsAuthenticated] = useState(false);

  const toggleSecondSidebar = () => {
    setSecondSidebarExpanded(!secondSidebarExpanded);
  };

  const toggleTopicsLoading = () => {
    setTopicsLoading(prevState => !prevState);
  };
  
  return (
    <AuthProvider value={{ spotifyIsAuthenticated, setIsAuthenticated }}>
        <Router>
          {!spotifyIsAuthenticated && <SignInFormModal/>}
          <div className={!spotifyIsAuthenticated ? styles["app-not-logged-in"] : ""}>
            <SideBar navSidebarExpanded={navSidebarExpanded}
                    setNavSidebarExpanded={setNavSidebarExpanded}
                    secondSidebarExpanded={secondSidebarExpanded} 
                    topicsLoading={topicsLoading}
                    toggleTopicsLoading={toggleTopicsLoading} 
                    setTopics={setTopics}
                    setCurrLoadedShow={setCurrLoadedShow}/>
            <div class={styles.app}>
              <Routes>  
                <Route path="/" element={<FindShows secondSidebarExpanded={secondSidebarExpanded} toggleSecondSidebar={toggleSecondSidebar}/>} />
                <Route path="/topics/:show_id" element={<DisplayTopics navSidebarExpanded={navSidebarExpanded}
                                                                      secondSidebarExpanded={secondSidebarExpanded} 
                                                                      toggleSecondSidebar={toggleSecondSidebar}
                                                                      toggleTopicsLoading={toggleTopicsLoading}
                                                                      topicsLoading={topicsLoading}
                                                                      topics={topics}
                                                                      currLoadedShow={currLoadedShow}/>}/>
              </Routes>
            </div>
          </div>
        </Router>
    </AuthProvider>
  );
}

export default App;

/*
  return (
    <_SandBox></_SandBox>
  );
}

export default App;
*/






