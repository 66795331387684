import styles from './LoadingScreen.module.css'

function LoadingScreen() {
    return (
            <div className={styles.loader}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
    );
}

export default LoadingScreen;