import {Row, Card, Col} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import React, { useState } from 'react';
import styles from './ShowList.module.scss';

function ShowList({shows}) {
    
    const navigate = useNavigate();
    const handleCardClick = (show, show_id) => {
        navigate('/topics/' + show.id, {state : {show: show}});
    };

    return (
        <Row className='d-flex'>
            {shows.map( (show, i) => {
            return (
            <Col 
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className={`d-flex ${styles.showCol}`} 
                key={i}
            >
            <Card 
                className={styles.showCard} 
                key={show.id} 
                onClick={() => handleCardClick(show)}
            >
                <Card.Img src={show.images[0].url} />
                <Card.Body className={styles.title}>
                    <Card.Title>
                        {show.name}
                    </Card.Title>
                </Card.Body>
            </Card>
            </Col>
            )
            })}
        </Row>
    );
}

export default ShowList
     