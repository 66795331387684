import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import { Nav, Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSearch, faThumbtack } from '@fortawesome/free-solid-svg-icons';
import TopicsSetting from '../TopicsSetting/TopicsSetting.js';
import styles from './SideBar.module.scss';

function SideBar({navSidebarExpanded, 
                  setNavSidebarExpanded, 
                  secondSidebarExpanded, 
                  topicsLoading, 
                  toggleTopicsLoading, 
                  setTopics, 
                  setCurrLoadedShow}) {

  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const handleSearchClick = () => {
    navigate('/');
  };

  const toggleNavSidebar = () => {
    setNavSidebarExpanded(!navSidebarExpanded)
    //setExpanded(!expanded);
  };

  return (
    <>
      <div className={navSidebarExpanded ? styles.navSidebarExpanded : styles.navSidebar}>
        <FontAwesomeIcon onClick={toggleNavSidebar} className={styles.hamburgerIcon} icon={faBars} size='md' /> 
        <div className={styles.sidebarRow} onClick={() => {handleSearchClick();}}>
          <FontAwesomeIcon className={styles.sidebarIcon} icon={faSearch} size='md'/>
          <span className={styles.navLabel}>
            Search
          </span>
        </div>
        <div className={styles.sidebarRow}>
          <FontAwesomeIcon className={styles.sidebarIcon} icon={faThumbtack} size='md' />
          <span className={styles.navLabel}>
            Favorites
          </span>
        </div>
      </div>
      <div className={`${navSidebarExpanded ? styles.secondSidebarNavExp: styles.secondSidebar}
                       ${secondSidebarExpanded ? styles.secondSidebarExp : ""} 
                       ${secondSidebarExpanded && navSidebarExpanded ? styles.secSidebarExpNavExp : ""}`}>
        <div className={styles.topics_setting_container}>
          {secondSidebarExpanded && <TopicsSetting topicsLoading={topicsLoading} 
                          toggleTopicsLoading={toggleTopicsLoading} 
                          setTopics={setTopics} 
                          setCurrLoadedShow={setCurrLoadedShow}
                          className={styles.topics_setting}
          />}
        </div>
      </div>
    </>
  );
}

export default SideBar;